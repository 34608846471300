<template>
  <Header title="Contact Us" />
  <div v-if="sectionData && contactData" class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="relative bg-white shadow-xl rounded-lg overflow-hidden">
        <h2 class="sr-only">{{ sectionData[0].topText }}</h2>
        <div class="bg-white">
          <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-12 lg:px-8">
            <div class="divide-y-2 divide-gray-200">
              <div class="lg:grid lg:grid-cols-3 lg:gap-8">
                <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  {{ sectionData[1].topText }}
                </h2>
                <div
                  class="
                    mt-8
                    grid grid-cols-1
                    gap-12
                    sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12
                    lg:mt-0 lg:col-span-2
                  "
                  v-for="contactInfo in contactData"
                  :key="contactInfo.email"
                >
                  <div>
                    <h4 class="text-lg leading-6 font-medium">Email</h4>
                    <dl class="mt-2 text-base text-gray-500">
                      <div>
                        <dt class="sr-only">Email</dt>
                        <dd>
                          <a class="hover:text-black" href="mailto:"
                            >{{ contactInfo.email }}</a
                          >
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h4 class="text-lg leading-6 font-medium">Phone</h4>
                    <dl class="mt-2 text-base text-gray-500">
                      <div class="mt-1">
                        <dt class="sr-only">Phone number</dt>
                        <dd>
                          <a
                            class="hover:text-black"
                            :href="'tel:' + contactInfo.phoneNumber"
                            >{{ contactInfo.phoneNumber }}</a
                          >
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h4 class="text-lg leading-6 font-medium">Toll Free</h4>
                    <dl class="mt-2 text-base text-gray-500">
                      <div class="mt-1">
                        <dt class="sr-only">Toll-free</dt>
                        <dd>
                          <a class="hover:text-black" :href="'tel:' + contactInfo.tollFreeNumber"
                            >{{ contactInfo.tollFreeNumber }}</a
                          >
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <h4 class="text-lg leading-6 font-medium">Fax</h4>
                    <dl class="mt-2 text-base text-gray-500">
                      <div class="mt-1">
                        <dt class="sr-only">Fax</dt>
                        <dd>{{ contactInfo.faxNumber }}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8">
                <h2 class="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                  Locations
                </h2>
                <div
                  class="
                    mt-8
                    grid grid-cols-1
                    gap-12
                    sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12
                    lg:mt-0 lg:col-span-2
                  "
                  v-if="locationData" 
                >
                  <div v-for="location in locationData" :key="location.locationName">
                    <h4 class="text-lg leading-6 font-medium">{{ location.locationName }}</h4>
                    <div class="mt-2 text-base text-gray-500">
                      <p>{{ location.address }}</p>
                      <p class="mt-1">{{ location.city }}, {{ location.province }} {{ location.postalCode }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 hidden">
          <!-- Contact information -->
          <div
            class="
              relative
              overflow-hidden
              py-10
              px-6
              bg-accent
              sm:px-10
              xl:p-12
            "
          >
            <div
              class="absolute inset-0 pointer-events-none sm:hidden"
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="343"
                height="388"
                viewBox="0 0 343 388"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                  fill="url(#linear1)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear1"
                    x1="254.553"
                    y1="107.554"
                    x2="961.66"
                    y2="814.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="
                hidden
                absolute
                top-0
                right-0
                bottom-0
                w-1/2
                pointer-events-none
                sm:block
                lg:hidden
              "
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="359"
                height="339"
                viewBox="0 0 359 339"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                  fill="url(#linear2)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear2"
                    x1="192.553"
                    y1="28.553"
                    x2="899.66"
                    y2="735.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <div
              class="
                hidden
                absolute
                top-0
                right-0
                bottom-0
                w-1/2
                pointer-events-none
                lg:block
              "
              aria-hidden="true"
            >
              <svg
                class="absolute inset-0 w-full h-full"
                width="160"
                height="678"
                viewBox="0 0 160 678"
                fill="none"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                  fill="url(#linear3)"
                  fill-opacity=".1"
                />
                <defs>
                  <linearGradient
                    id="linear3"
                    x1="192.553"
                    y1="325.553"
                    x2="899.66"
                    y2="1032.66"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#fff" />
                    <stop offset="1" stop-color="#fff" stop-opacity="0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <h5 class="text-lg font-medium text-white">Contact information</h5>
            <p class="mt-6 text-base text-indigo-50 max-w-3xl">
              3380 South Service Rd. <br />
              Burlington, Ontario L7N 3J5
            </p>
            <dl class="mt-8 space-y-1">
              <a
                href="tel:+19056318190"
                class="block text-gray-50 hover:text-accent-lightest"
                >Tel: (905) 631-8190</a
              >
              <a
                href="tel:+19056318194"
                class="block text-gray-50 hover:text-accent-lightest"
                >Fax: (905) 631-8194</a
              >
              <a
                href="tel:+18664664603"
                class="block text-gray-50 hover:text-accent-lightest"
                >Toll Free: 1-866-466-4603</a
              >
            </dl>
          </div>

          <!-- Contact form -->
          <div class="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h5 class="text-lg font-medium text-gray-900">Send us a message</h5>
            <form
              action="#"
              method="POST"
              class="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <label
                  for="first-name"
                  class="block text-sm font-medium text-gray-900"
                  >First name</label
                >
                <div class="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autocomplete="given-name"
                  />
                </div>
              </div>
              <div>
                <label
                  for="last-name"
                  class="block text-sm font-medium text-gray-900"
                  >Last name</label
                >
                <div class="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autocomplete="family-name"
                  />
                </div>
              </div>
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-900"
                  >Email</label
                >
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                  />
                </div>
              </div>
              <div>
                <div class="flex justify-between">
                  <label
                    for="phone"
                    class="block text-sm font-medium text-gray-900"
                    >Phone</label
                  >
                  <span id="phone-optional" class="text-sm text-gray-500"
                    >Optional</span
                  >
                </div>
                <div class="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autocomplete="tel"
                    aria-describedby="phone-optional"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="subject"
                  class="block text-sm font-medium text-gray-900"
                  >Subject</label
                >
                <div class="mt-1">
                  <input type="text" name="subject" id="subject" />
                </div>
              </div>
              <div class="sm:col-span-2">
                <div class="flex justify-between">
                  <label for="message" class="block text-sm font-medium"
                    >Message</label
                  >
                  <span id="message-max" class="text-sm text-gray-500"
                    >Max. 500 characters</span
                  >
                </div>
                <div class="mt-1">
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    class=""
                    aria-describedby="message-max"
                  />
                </div>
              </div>
              <div class="sm:col-span-2 sm:flex sm:justify-end">
                <button
                  type="submit"
                  class="
                    mt-2
                    w-full
                    inline-flex
                    items-center
                    justify-center
                    px-6
                    py-3
                    border border-transparent
                    rounded-md
                    shadow-sm
                    text-base
                    font-medium
                    transition
                    btn-dark
                    sm:w-auto
                  "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Container from "../components/Container.vue";
import Header from "../components/Header.vue";
import { MailIcon, PhoneIcon } from "@heroicons/vue/outline";
import { OcContentService } from "../services/ocContentService";
export default {
  components: { Header, MailIcon, PhoneIcon, Container },
  data() {
    return {
      sectionData: null,
      contactData: null,
      locationData: null,
      isLoading: null,
    };
  },
  async created() {
    this.isLoading = true;
    this.fetchOcData();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Contact",
        "KrickersSectionContent"
      );
      this.contactData = await OcContentService.fetchPageData(
        "Contact",
        "KrickersContactInformationItem"
      );
      this.locationData = await OcContentService.fetchPageData(
        "Contact",
        "KrickersLocationItem"
      );
    },
  },
};
</script>
